<template>
<div>
    <!--====== APPIE HERO PART START ======-->
    
    <section class="appie-hero-area-2 mb-90">
        <div class="container appie-hero-area-2-content">
            <img src="../assets/images/logo-banner-new.png" alt="" class="logo-banner">
            <h1>{{ data_homepage.title_banner }}</h1>
            <div v-html="data_homepage.subtitle_banner"></div>
            <a :href="button_banner_url">{{ button_banner_label }}</a>
            <img :src="img_banner" alt="">
        </div>
            <img src="../assets/images/background-banner.png" alt="" class="img-background">
    </section>

    <!--====== APPIE HERO PART ENDS ======-->

    <!--====== APPIE CUSTOM DESC RISE STARTS======-->

    <section class="appie-desc" id="about"> 
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-5 desc">
                    <img :src="img_about" alt="">
                </div>
                <div class="col-lg-6 pd-top-mobile desc">
                    <h1 class="title-gradient">{{ data_homepage.title_about }}</h1>
                    <p>{{ data_homepage.description_about }}</p>
                </div>
            </div>
        </div>
        <img src="../assets/images/img-background-about.png" alt="" class="img-background-about">
    </section>

    <!--====== APPIE CUSTOM DESC RISE ENDS======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->
    
    <section class="appie-services-2-area" id="features">

        <h1 class="title-gradient">{{ data_homepage.title_feature }}</h1>

        <div v-for="(data_feature, index) in data_homepage.features" :value="index" :key="data_feature.id">
            <div class="container-fluid" v-if="index == 0">
                <div class="row feature">
                    <div class="col-lg-6 col-md-12 content-left-service">
                        <img :src="url.concat(data_feature.img_feature.url)" alt="">
                        <h3>{{ data_feature.title_feature }}</h3>
                        <p>{{ data_feature.desc_feature }}</p>
                        <ul id="feature">
                            <li v-for="(detail, index) in data_feature.list_detail_features" :value="index" :key="detail.id">{{ detail.detail_feature }}</li>
                        </ul>
                        <img src="../assets/images/icon/after-list.png" alt="">
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <img :src="url.concat(data_feature.icon_feature.url)" alt="">
                    </div>
                    <img src="../assets/images/lingkaran2.png" alt="" class="feature-1">
                </div>
            </div>

            <div class="container-fluid" v-else-if="index == 1">
                <div class="row feature">
                    <div class="col-lg-6 col-md-12">
                        <img :src="url.concat(data_feature.icon_feature.url)" alt="">
                    </div>
                    <div class="col-lg-6 col-md-12 content-right-service">
                        <img :src="url.concat(data_feature.img_feature.url)" alt="">
                        <h3>{{ data_feature.title_feature }}</h3>
                        <p>{{ data_feature.desc_feature }}</p>
                        <ul id="feature">
                            <li v-for="(detail, index) in data_feature.list_detail_features" :value="index" :key="detail.id">{{ detail.detail_feature }}</li>
                        </ul>
                        <img src="../assets/images/icon/after-list.png" alt="">
                    </div>
                    <img src="../assets/images/lingkaran3.png" alt="" class="feature-2">
                </div>
            </div>

            <div class="container-fluid" v-else-if="index == 3">
                <div class="row feature">
                    <div class="col-lg-6 col-md-12">
                        <img :src="url.concat(data_feature.icon_feature.url)" alt="">
                    </div>
                    <div class="col-lg-6 col-md-12 content-right-service">
                        <img :src="url.concat(data_feature.img_feature.url)" alt="">
                        <h3>{{ data_feature.title_feature }}</h3>
                        <p>{{ data_feature.desc_feature }}</p>
                        <ul id="feature">
                            <li v-for="(detail, index) in data_feature.list_detail_features" :value="index" :key="detail.id">{{ detail.detail_feature }}</li>
                        </ul>
                        <img src="../assets/images/icon/after-list.png" alt="">
                    </div>
                    <img src="../assets/images/lingkaran4.png" alt="" class="feature-4">
                </div>
            </div> 

            <div class="container-fluid" v-else>
                <div class="row feature" style="padding-right: 0px !important;">
                    <div class="col-lg-6 col-md-12 content-left-service">
                        <img :src="url.concat(data_feature.img_feature.url)" alt="">
                        <h3>{{ data_feature.title_feature }}</h3>
                        <p>{{ data_feature.desc_feature }}</p>
                        <ul id="feature">
                            <li v-for="(detail, index) in data_feature.list_detail_features" :value="index" :key="detail.id">{{ detail.detail_feature }}</li>
                        </ul>
                        <img src="../assets/images/icon/after-list.png" alt="">
                    </div>
                    <div class="col-lg-6 col-md-12" style="padding-right: 0px !important">
                        <img :src="url.concat(data_feature.icon_feature.url)" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== APPIE SERRVICE 2 PART ENDS ======-->
    
    <!--====== APPIE PRICING 2 PART START ======-->
    
    <section class="appie-pricing-2-area" id="pricing">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-md-12 pricing-left">
                    <h1 class="title-gradient">{{ data_homepage.title_pricing }}</h1>
                    <div id="pricing-desc">
                        <ul id="pricing">
                            <li  v-for="(data_feature, index) in data_homepage.list_feature_general" :value="index" :key="data_feature.id">{{ data_feature.detail_feature }}</li>
                        </ul>
                    </div>

                    <h4 class="content-count-users"> {{ data_homepage.question_count_users }}
                        <div id="count-user" v-if="count_user > 50">> {{ count_user }}</div>
                        <div id="count-user" v-else>{{ count_user }}</div>
                    </h4> 
                    <div class="slidecontainer">
                        <input type="range" min="1" max="51" value="1" v-model="count_user" class="slider" id="myRange" oninput="changeBgSlider()">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 pricing-right">
                            <div class="box-pricing-with-desc fade-in" v-if="count_user >= 0 && count_user <= 5">
                                <h4>{{ data_homepage.pricing_regulars[0].name_package }} <span class="discount" v-if="data_homepage.pricing_regulars[0].discount != ''">{{ data_homepage.pricing_regulars[0].discount }}</span></h4>

                                <p>This package fit to your business with :</p>
                                <ul>
                                    <li>{{ data_homepage.pricing_regulars[0].count_users }} Max Users</li>
                                </ul>

                                <div class="price">
                                    <h5>Rp.<span> {{ data_homepage.pricing_regulars[0].price }} </span> / month</h5>
                                    <a :href="data_homepage.pricing_regulars[1].button_price.url">{{ data_homepage.pricing_regulars[3].button_price.label }}</a>
                                </div>
                            </div>
                            <div class="box-pricing-with-desc fade-in" v-else-if="count_user > 5 && count_user <= 20">
                                <h4>{{ data_homepage.pricing_regulars[1].name_package }} <span class="discount" v-if="data_homepage.pricing_regulars[1].discount != ''">{{ data_homepage.pricing_regulars[1].discount }}</span></h4>

                                <p>This package fit to your business with :</p>
                                <ul>
                                    <li>{{ data_homepage.pricing_regulars[1].count_users }} Max Users</li>
                                </ul>

                                <div class="price">
                                    <h5>Rp.<span> {{ data_homepage.pricing_regulars[1].price }} </span> / month</h5>
                                    <a :href="data_homepage.pricing_regulars[2].button_price.url">{{ data_homepage.pricing_regulars[3].button_price.label }}</a>
                                </div>
                            </div>
                            <div class="box-pricing-with-desc fade-in" v-else-if="count_user > 20 && count_user <= 50">
                                <h4>{{ data_homepage.pricing_regulars[2].name_package }} <span class="discount" v-if="data_homepage.pricing_regulars[2].discount != ''">{{ data_homepage.pricing_regulars[2].discount }}</span></h4>

                                <p>This package fit to your business with :</p>
                                <ul>
                                    <li>{{ data_homepage.pricing_regulars[2].count_users }} Max Users</li>
                                </ul>

                                <div class="price">
                                    <h5>Rp.<span> {{ data_homepage.pricing_regulars[2].price }} </span> / month</h5>
                                    <a :href="data_homepage.pricing_regulars[2].button_price.url">{{ data_homepage.pricing_regulars[3].button_price.label }}</a>
                                </div>
                            </div>
                            <div class="box-pricing-with-desc fade-in" v-else>
                                 <h4>{{ data_homepage.pricing_regulars[3].name_package }} <span class="discount" v-if="data_homepage.pricing_regulars[3].discount != ''">{{ data_homepage.pricing_regulars[3].discount }}</span></h4>

                                <p>This package fit to your business with :</p>
                                <ul>
                                    <li>{{ data_homepage.pricing_regulars[3].count_users }} Max Users</li>
                                </ul>

                                <div class="price">
                                    <h5>Rp.<span> {{ data_homepage.pricing_regulars[3].price }} </span> / month</h5>
                                    <a :href="data_homepage.pricing_regulars[3].button_price.url">{{ data_homepage.pricing_regulars[3].button_price.label }}</a>
                                </div>
                            </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE PRICING 2 PART ENDS ======-->
    
    <!--====== APPIE CUSTOM APPS MOBILE RISE STARTS======-->

    <section class="appie-apps new-apps">
        <div class="container-fluid">
            <div class="row">
                    <div class="col-lg-5 col-md-12 offset-lg-1 apps-left">
                        <h1>{{ data_homepage.title_apps_mobile }}</h1>
                        <p>{{ data_homepage.subtitle_apps_mobile }}</p>
                        <a :href="button_apps_url" class ="button_apps_url"> {{ button_apps_label }}</a>
                        
                        <div class="icon-store">

                               <a :href="data_homepage.playstoreLink" target="_blank" rel="noopener noreferrer"> <img :src="img_ic_android" alt="" class=""> </a>
                                <a :href="data_homepage.appstoreLink" target="_blank" rel="noopener noreferrer"> <img :src="img_ic_ios" alt="" class=""> </a>           
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 apps-right">
                        <img :src="img_apps" alt="" class="">
                    </div>
            </div>
        </div>
            <img src="../assets/images/background-don't.png" alt="" class="background-apps">
    </section>

    <!--====== APPIE CUSTOM APPS MOBILE RISE ENDS======-->
    <Footer></Footer>
    <router-view />
</div>

</template>

<script type="text/javascript">
import Footer from '../components/Footer'
import jQuery from 'jquery'

/* eslint-disable */

const axios = require('axios')
export default {
  name: 'Home',
  metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords }
            ]    
        }
    },
  components: {
    Footer
  },
  data() {
    return {
      nama_lengkap: null,
      email: null,
      nomor_hp: null, 
      message_free_trial:null,
      message_req_beta:null,
      nama_lengkap_cp: null,
      email_cp: null,
      no_hp_cp: null,
      estimasi_user_cp: null,
      pesan_cp: null,
      message_custom_package:null,
      data_homepage: [],
      url: "https://cms.getrise.id/",
      count_user: 1,
      img_banner: null,
      button_banner_label: null,
      button_banner_url: null,
      img_about: null,
      button_apps_label: null,
      button_apps_url: null,
      img_apps: null,
      img_ic_android: null,
      img_ic_ios: null,
      playstoreLink : null,
      appstoreLink : null,
      index: null,
      metaTitle: null,
      metaDescription: null,
      keywords: null,
    }
  }, 
  created() {
    axios
      .get(`https://cms.getrise.id/homepage`)
      .then(response => {
        this.data_homepage = response.data
        this.img_banner = this.url.concat(this.data_homepage.img_banner.url),
        this.button_banner_label = this.data_homepage.button_banner.label,
        this.button_banner_url = this.data_homepage.button_banner.url,
        this.img_about = this.url.concat(this.data_homepage.img_about.url),
        this.button_apps_label = this.data_homepage.button_apps_mobile.label
        this.button_apps_url = this.data_homepage.button_apps_mobile.url
        this.img_apps = this.url.concat(this.data_homepage.image_apps_mobile.url)
        this.img_ic_android = this.url.concat(this.data_homepage.playstore.url)
        this.img_ic_ios = this.url.concat(this.data_homepage.appstore.url)
        this.metaTitle = this.data_homepage.seo.metaTitle
        this.metaDescription = this.data_homepage.seo.metaDescription
        this.keywords = this.data_homepage.seo.keywords
    })
    
  },
  methods: {
      sendContact() {
      axios({
        method: 'post',
        url: 'https://cms.getrise.id/contact-forms',
        data: {
          nama_lengkap: this.nama_lengkap,
          email: this.email,
          nomor_hp: this.nomor_hp,
          value: 51
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.message_req_beta = "Form request Beta Version berhasil terkirim."
          }
        })
      },
      postTrial() {
      axios({
        method: 'post',
        url: 'https://cms.getrise.id/free-trials',
        data: {
          email: this.email,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.message_free_trial = "Request Trial RISE berhasil terkirim."
          }
        })
      },
      customPackage(){
          axios({
              method: 'post',
              url: 'https://cms.getrise.id/paket-customs',
              data: {
                nama_lengkap: this.nama_lengkap_cp,
                email: this.email_cp,
                nomor_hp: this.no_hp_cp,
                estimasi_user: this.estimasi_user_cp,
                pesan: this.pesan_cp
              }
          })
            .then((response) => {
            if (response.status == 200) {
                this.message_custom_package = "Permintaan custom package berhasil terkirim."
            }
        })
      }
  },
  mounted(){
    // this.jQuerynextTick(()=>{

        jQuery( ".col-lg-4.col-md-6:nth-child(3)" ).addClass( "offset-lg-2" );

        jQuery("#toggle-btn").click(function(){
                jQuery(".offcanvas_menu_wrapper").css('margin-left', '0px');
        })

        jQuery(".canvas_close a").click(function(){
            jQuery(".offcanvas_menu_wrapper").css('margin-left', '-333px');
        })

        function toggleHandle() {
            if (toggleSwitch.hasClass('on')) {
                yearTabContent.fadeOut();
                monthTabContent.fadeIn();
                monthTabTitle.addClass('active');
                yearTabTitle.removeClass('active');
            } else {
                monthTabContent.fadeOut();
                yearTabContent.fadeIn();
                yearTabTitle.addClass('active');
                monthTabTitle.removeClass('active');
            }
        }

        if (jQuery('.nav-item').length) {
            var toggleSwitch = jQuery('.nav-item');
            var monthTabTitle = jQuery('.nav-link#pills-home-tab');
            var yearTabTitle = jQuery('.nav-link#pills-profile-tab');
            var monthTabContent = jQuery('#pills-home');
            var yearTabContent = jQuery('#pills-profile');
            monthTabContent.fadeIn();
            yearTabContent.fadeOut();

            monthTabTitle.on('click', function () {
                toggleSwitch.addClass('on').removeClass('off');
                toggleHandle();
                return false;
            });
            yearTabTitle.on('click', function () {
                toggleSwitch.addClass('off').removeClass('on');
                toggleHandle();
                return false;
            });
            toggleSwitch.on('click', function () {
                toggleSwitch.toggleClass('on off');
                toggleHandle();
            });
        }
        // })

        var slider = document.getElementById("myRange");
        var output = document.getElementById("count-user");
        output.innerHTML = slider.value; // Display the default slider value

        // Update the current slider value (each time you drag the slider handle)
        slider.oninput = function() {
            output.innerHTML = this.value;
        }

        const mySlider = document.getElementById("myRange");
        function changeBgSlider() {
            var valPercent = (mySlider.value / mySlider.max) * 100;
            mySlider.style.background = `linear-gradient(to right, #3264fe ${valPercent}%, #d5d5d5 ${valPercent})`
        }
  }
}
</script>
