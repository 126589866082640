<template>
<div>
    <div id="tnc">
        <div class="row">
            <div class="container-fluid">
                <div class="col-lg-10 offset-lg-1 pt-120">
                    <div class="appie-hero-content-2">
                        <h1 class="appie-title pb-10 pb-10">{{ data_tnc.title_page}}</h1>
                    </div>
                    <p>{{ data_tnc.description_page }}</p>
                    <div v-html="data_tnc.informasi_umum" id="informasi_umum"></div>

                    <div v-html="data_tnc.persetujuan" id="persetujuan"></div>

                    <div v-html="data_tnc.penggunaan_layanan_dan_aplikasi" id="penggunaan_layanan_dan_aplikasi"></div>

                    <div v-html="data_tnc.layanan_percobaan" id="layanan_percobaan"></div>

                    <div v-html="data_tnc.jangka_waktu_berlangganan" id="jangka_waktu_berlangganan"></div>
                  
                    <div v-html="data_tnc.kewajiban_user" id="kewajiban_user"></div>

                    <div v-html="data_tnc.penghentian_layanan" id="penghentian_layanan"></div>

                    <div v-html="data_tnc.perjanjian_tingkat_layanan" id="perjanjian_tingkat_layanan"></div>

                    <div v-html="data_tnc.transmisi_elektronik" id="transmisi_elektronik"></div>

                    <div v-html="data_tnc.ketentuan_lain_lain" id="ketentuan_lain_lain"></div>
                    
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
    <router-view />
</div>
</template>

<script>
import Footer from '../components/Footer'
import jQuery from 'jquery'
const axios = require('axios')
export default {
    name: "TermsandCondition",
    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords }
            ]    
        }
    },
    components: {
        Footer
    },
    data() {
        return {
            data_tnc: [],
            metaTitle: null,
            metaDescription: null,
            keywords: null,
        }
    },
    created() {
        axios
        .get(`https://cms.getrise.id/term-and-conditions`)
        .then(response => {
            this.data_tnc = response.data
            this.metaTitle = this.data_tnc.seo.metaTitle
            this.metaDescription = this.data_tnc.seo.metaDescription
            this.keywords = this.data_tnc.seo.keywords
        })
    },
    mounted(){
        jQuery("#toggle-btn").click(function(){
                jQuery(".offcanvas_menu_wrapper").css('margin-left', '0px');
        })

        jQuery(".canvas_close a").click(function(){
            jQuery(".offcanvas_menu_wrapper").css('margin-left', '-333px');
        })
    }
}
</script>
