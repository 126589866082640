<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>

<script>
// import '@/assets/js/bootstrap.min.js'
export default {
  name: 'App',
  watch: {
      '$route':{
        handler: (to, from) => {
          document.title = to.meta.title || 'Get Rise'
        },
         immediate: true
      }
    },
}
</script>

<style>
  @import './assets/css/animate.min.css';
  @import './assets/css/bootstrap.min.css';
  @import './assets/css/custom-animation.css';
  @import './assets/css/default.css';
  @import './assets/css/magnific-popup.css';
  @import './assets/css/slick.css';
  @import './assets/css/style.css';
  @import './assets/css/custom-style.css';
</style>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
