import Vue from 'vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheck, faBars, faEnvelope, faTimes, faSearch} from '@fortawesome/free-solid-svg-icons'

Vue.config.productionTip = false

library.add(faCheck, faBars, faEnvelope, faTimes, faSearch)

Vue.component('font-awesome-icon', FontAwesomeIcon)

global.jQuery = jQuery
global.$ = jQuery

function toggleHandle() {
  if (toggleSwitch.hasClass('on')) {
      yearTabContent.fadeOut();
      monthTabContent.fadeIn();
      monthTabTitle.addClass('active');
      yearTabTitle.removeClass('active');
  } else {
      monthTabContent.fadeOut();
      yearTabContent.fadeIn();
      yearTabTitle.addClass('active');
      monthTabTitle.removeClass('active');
  }
}

if (jQuery('.nav-item').length) {
  var toggleSwitch = jQuery('.nav-item');
  var monthTabTitle = jQuery('.nav-link#pills-home-tab');
  var yearTabTitle = jQuery('.nav-link#pills-profile-tab');
  var monthTabContent = jQuery('#pills-home');
  var yearTabContent = jQuery('#pills-profile');
  // hidden show deafult;
  monthTabContent.fadeIn();
  yearTabContent.fadeOut();

  monthTabTitle.on('click', function () {
      toggleSwitch.addClass('on').removeClass('off');
      toggleHandle();
      return false;
  });
  yearTabTitle.on('click', function () {
      toggleSwitch.addClass('off').removeClass('on');
      toggleHandle();
      return false;
  });
  toggleSwitch.on('click', function () {
      toggleSwitch.toggleClass('on off');
      toggleHandle();
  });
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
