import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import TermsandCondition from '../views/TermsandCondition'
import Faq from '../views/Faq'
import Header from '@/components/Header'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta, { 
    refreshOnceOnNavigation: true
})

const routes = [
    {
    path: '/',
    component: Header,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/terms-and-conditions',
            name: 'TermsandCondition',
            component: TermsandCondition
        },
        {
            path: '/faq',
            name: 'Faq',
            component: Faq,
            // meta: {
            //     title: 'FAQ'
            // }
        }
    ]
    },
];

const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
  
export default router