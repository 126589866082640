<template>
    <div>
        <div id="faq">
            <div class="row">
                <div class="container-fluid">
                    <div class="col-lg-10 offset-lg-1 pt-120">
                        <form action="" id="form-search">
                            <input type="text" v-model="search" placeholder="Search..">
                            <font-awesome-icon icon="search" />
                        </form>

                        <div class="appie-hero-content-2">
                            <h1 class="appie-title pb-10 pb-10">{{ response_faq.title_page }}</h1>
                        </div>
                        <vsa-list v-if="search == ''">
                            <!-- Here you can use v-for to loop through items  -->
                            <vsa-item v-for="(detail_faq, index) in response_faq.faq" :value="index" :key="detail_faq.id">
                                <vsa-heading>
                                    {{ detail_faq.title }}
                                </vsa-heading>

                                <vsa-content>
                                  <div v-html="detail_faq.description"></div>
                                </vsa-content>
                            </vsa-item>
                        </vsa-list>

                        <vsa-list v-if="search != ''">
                            
                            <div v-if="filteredFaq.length == 0">
                                    <p class="not-found">
                                        The keyword you are looking for is not found..
                                    </p>
                            </div>
                            <vsa-item v-for="(faq, index) in filteredFaq" :value="index" :key="faq.id">
                                    <vsa-heading>
                                        {{ faq.title }}
                                    </vsa-heading>

                                    <vsa-content> 
                                    <div v-html="faq.description"></div>
                                    </vsa-content>
                            </vsa-item>
                        </vsa-list>
                    </div>
                </div>
            </div>
        </div>
        
    <Footer></Footer>
    </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import Footer from '../components/Footer'
const axios = require('axios')
/* eslint-disable */
export default ({
    name: 'Faq',
    metaInfo() {
        return {
            title: this.metaTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords }
            ]
        }
    },
    data() {
        return {
            response_faq: {},
            faq: null,
            search: "",
            metaTitle: null,
            metaDescription: null,
            keywords: null,
        }
    },
    components: {
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon,
        Footer
    },
    created() {
        axios
        .get(`https://cms.getrise.id/faq`)
        .then(response => {
            this.response_faq = response.data
            this.faq =  response.data.faq
            this.metaTitle = this.response_faq.seo.metaTitle
            this.metaDescription = this.response_faq.seo.metaDescription
            this.keywords = this.response_faq.seo.keywords
        });
    },
    mounted() {    

    },
    computed: {
        filteredFaq() {
            return this.faq.filter(faq_detail => {
                return faq_detail.description.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    }
})
</script>
