<template>
  <div>
    <!--====== OFFCANVAS MENU PART START ======-->
    <div class="offcanvas_menu">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="offcanvas_menu_wrapper">
                        <div class="canvas_close">
                            <a href="javascript:void(0)"><font-awesome-icon icon="times" /></a>  
                        </div>
                        <div class="offcanvas-brand text-center mb-40">
                            <img :src="logo" alt="">
                        </div>
                        <div id="menu" class="text-left ">
                            <ul class="offcanvas_main_menu">
                                <li class="menu-item-has-children"  v-for="(link_navbar, index) in navbar.Header" :value="index" :key="link_navbar.id">
                                    <a :href="link_navbar.link">{{ link_navbar.caption }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="offcanvas-social">
                             <div class="social-icon" v-for="(result_sosmed_footer, index) in footer.sosmed_footer" :value="index" :key="result_sosmed_footer.id">
                                <a :href="result_sosmed_footer.link">
                                    <img :src="url.concat(result_sosmed_footer.icon_sosmed.url)">
                                </a>
                             </div>
                        </div>
                        <!-- <div class="footer-widget-info">
                            <ul>
                                <li v-for="(result_get_in_touch, index) in footer.get_in_touch" :value="index" :key="result_get_in_touch.id">
                                    <a :href="result_get_in_touch.link"><img :src="url.concat(result_get_in_touch.icon_get_in_touch.url)">{{ result_get_in_touch.caption }}</a>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--====== OFFCANVAS MENU PART ENDS ======-->
   
    <!--====== APPIE HEADER PART START ======-->
    
        
    <header class="appie-header-area appie-header-2-area appie-sticky sticky"> 
        <div class="container-fluid">
            <div class="header-nav-box">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1 ">
                        <div class="appie-logo-box">
                            <a href="https://getrise.id/">
                            <!-- <img src="@/assets/images/logo-full-2.png" alt=""> -->
                            <img :src="logo" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-1 col-sm-1 order-3 order-sm-2">
                        <div class="appie-header-main-menu">
                            <ul>
                                <li v-for="(link_navbar, index) in navbar.Header" :value="index" :key="link_navbar.id">
                                    <a :href="link_navbar.link">{{ link_navbar.caption }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                     <div class="col-lg-2 col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                        <div class="appie-btn-box text-right">
                            <a class="main-btn ml-30" :href="nav_right_url">{{ nav_right_label }}</a>
                            <div class="toggle-btn ml-30 canvas_open d-lg-none d-block" id="toggle-btn">
                                 <font-awesome-icon icon="bars" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    
    <!--====== APPIE HEADER PART ENDS ======-->
    <router-view />
  </div>
</template>

<script>
const axios = require('axios')
export default {
    name: "Header",
    data() {
        return {
            scriptHeadGtm: null,
            scriptGtm1: null,
            scriptGtm2: null,
            navbar: [],
            logo: null,
            link: null,
            nav_right_url: null,
            nav_right_label: null,
            url: "https://cms.getrise.id",
            footer: [],
            description_footer: null
        }
    },    
    mounted() {
        var scriptTag = document.createElement("script");
        scriptTag.setAttribute("type", "text/javascript");
        axios
        .get(`https://cms.getrise.id/general`)
        .then(responseGeneral => {
            this.scriptHeadGtm = responseGeneral.data.header
            this.scriptGtm1 = responseGeneral.data.script_external_header1
            this.scriptGtm2 = responseGeneral.data.script_external_header2
            
            scriptTag.appendChild(document.createTextNode(this.scriptHeadGtm));
            document.getElementsByTagName("head")[0].appendChild(scriptTag);

            let head_gtm1 = document.createElement('script')
            head_gtm1.setAttribute('src', this.scriptGtm1)
            document.head.appendChild(head_gtm1)

            let head_gtm2 = document.createElement('script')
            head_gtm2.setAttribute('src', this.scriptGtm2)
            document.head.appendChild(head_gtm2)
        })
    },
    created() {
        axios
        .get(`https://cms.getrise.id/header`)
        .then(response => {
            this.navbar = response.data
            this.logo = this.url.concat(this.navbar.logo.url)
            this.nav_right_url = this.navbar.nav_right.link
            this.nav_right_label = this.navbar.nav_right.caption
            
        })
        axios
        .get(`https://cms.getrise.id/footer`)
        .then(response_footer => {
            this.footer = response_footer.data
            this.logo = this.url.concat(this.footer.description_footer.logo.url)
        })
        
    },
}

</script>