<template>
    <div>
    <!--====== APPIE FOOTER PART START ======-->

    <section class="appie-footer-area new-footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <div class="new-logo">
                        <img :src="logo" alt="">
                    </div>
                    <div class="copyright-text">
                        <p>Copyright © 2021, <a href="https://roketin.com/">PT Roketin Kreatif Teknologi</a>.</p>
                    </div>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="col-md-4">
                    <div class="new-social-icon" v-for="(result_sosmed_footer, index) in footer.sosmed_footer" :value="index" :key="result_sosmed_footer.id">
                        <a :href="result_sosmed_footer.link">
                            <img :src="url.concat(result_sosmed_footer.icon_sosmed.url)">
                        </a>
                    </div>
                    <div class="new-menu-footer">
                        <ul class="">
                            <li v-for="(result_menu_footer, index) in footer.menu_footer" :value="index" :key="result_menu_footer.id">
                                <a :href="result_menu_footer.link">{{ result_menu_footer.caption }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-md-2 git">
                  <strong>  Get in touch with us  </strong>
                </div>
                <div class="col-md-1 git">
                    hello@getrise.id
                </div>
                <div class="col-md-1 git">
                    +628112221483
                </div> -->
                <div class="col-md-4 git d-flex">
                  <!-- <strong>  Get in touch with us  </strong> -->
                  <strong>  {{ footer.title_footer_2 }}  </strong>
                  <a v-for="(result_new_get_in_touch, index) in footer.new_get_in_touch" :value="index" :key="result_new_get_in_touch.id" :href="result_new_get_in_touch.url">
                    {{ result_new_get_in_touch.label }}
                  </a>
                </div>
            </div>
        </div>
    </section>
    <!--====== APPIE FOOTER PART ENDS ======-->
    <router-view />
    <div v-html="scriptBody.body"></div>
    </div>
</template>

<script type="text/javascript">
const axios = require('axios')
export default {
    name: "Footer",
    data() {
        return {
            scriptBody: [],
            footer: [],
            logo: null,
            link: null,
            url: "https://cms.getrise.id",
            description_footer: null,
            dataGeneral: []
        }
    },
    created() {
        axios
        .get(`https://cms.getrise.id/footer`)
        .then(response => {
            this.footer = response.data
            this.logo = this.url.concat(this.footer.description_footer.logo.url)
        })
        axios
        .get(`https://cms.getrise.id/general`)
        .then(responseGeneral => {
            this.scriptBody = responseGeneral.data
        })
    }
}
</script>